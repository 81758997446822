import React from "react"
import liability from "../images/liability.jpeg";

const Commercial = () => {
    return (
        <div className="pages">
            <div className="container">
                <div className= "row">
                    <div className= "col-lg-6 col-xm-12">
                        <div className="pages-photo-wrap mb-5">
                            <img className="pages-profile-img" src={liability} alt="liability..."/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xm-12">
                        <h1 className="pages-heading">Commercial Insurance</h1>
                        <p>
                        Los dueños de negocios deben considerar cuidadosamente sus necesidades de seguros comerciales, que ofrecen una gama de protección vital relacionada con el negocio. Desde los vehículos hasta la propiedad y los empleados dentro del negocio, 
                        los propietarios pueden salvaguardar su inversión y disminuir el riesgo de un escenario que afecte su crecimiento y presencia. Las siguientes áreas representan algunas de las principales necesidades de seguros comerciales que cualquier empresa debe abordar.
                        </p>
                        <p>&#9642; Seguro de automóvil comercial – un vehículo que se usa en el contexto de un negocio debe tener su póliza; una póliza de seguro de automóvil personal no es suficiente, ya que no cubrirá elementos relacionados con el negocio. 
                        Si su empresa utiliza vehículos para realizar operaciones de rutina o incluso ocasionales, una póliza de automóvil comercial es imprescindible.</p>
                        <p>&#9642; Seguro de responsabilidad general comercial  (CGL) – las lesiones corporales, los daños a la propiedad, las lesiones personales y las lesiones por publicidad están cubiertos con una póliza de CGL. Un tipo conveniente de póliza comodín, 
                        muchas empresas pueden utilizarlo como punto de partida para sus necesidades de seguro, en términos de responsabilidad.</p>
                        <p>&#9642; Seguro de propiedad comercial – la póliza de seguro de propiedad comercial de TA podría considerarse como una versión comercial de una póliza de hogar. Encontrará artículos cubiertos similares, como incendios, 
                        robos y desastres naturales que pertenecen a la propiedad comercial. Si su negocio tiene algún tipo de ubicación física, requerirá una póliza de seguro de propiedad.</p>
                        <p>&#9642; Compensación de trabajadores – los trabajadores que se lesionan en el trabajo pueden depender de la compensación de trabajadores por la pérdida de ingresos y gastos médicos. 
                        Esta cobertura es comúnmente requerida por los empleadores y, a menudo, también se extiende a los empleados temporales.</p>
                        <p>&#9642; Paquete para propietarios de negocios  (BOP) – los propietarios de pequeñas empresas pueden cubrir muchas necesidades en una póliza BOP; a menudo incluye vehículos, daños a la propiedad, ingresos comerciales, 
                        propiedad y lesiones corporales, así como otros tipos de necesidades básicas de seguro.</p>
                        <p>
                        Los dueños de negocios pueden comparar cotizaciones en línea usando una agencia independiente. Esta es una opción valiosa que puede ahorrar tiempo y dinero, donde los propietarios pueden recibir cotizaciones de varias compañías de seguros de primer nivel a la vez.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Commercial;