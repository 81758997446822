import React from "react";
import {Link} from "react-scroll/modules";

const Footer = () => {
    return (
        <div className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <p><img src="https://img.icons8.com/ios/50/ffffff/secured-letter--v2.png" alt="Email" className="icon"/>   info@all-ia.com </p>
                        <br />
                        <p><img src="https://img.icons8.com/ios/50/ffffff/cell-phone.png" alt="Email" className="icon"/>   +1-407-930-6226 </p>
                        <br />
                        <p><img src="https://img.icons8.com/ios/50/ffffff/google-maps-new--v2.png" alt="Email" className="icon"/>   9501 Satellite Blvd Ste 105,<br />
                        &emsp;  &emsp;  &emsp;Orlando, Florida 32837</p>
                    </div>
                    <div className="col-lg-3 col-md-2 col-sm-6">
                        <div className="row">
                            <div className="col">
                                <Link smooth={true} to="home" className="fot-link" href="#">home <span className="sr-only">(current)</span></Link><br/>
                                <Link smooth={true} to="about-us" offset={-110} className="fot-link" href="#">about us</Link><br/>
                                <Link smooth={true} to="services" offset={-110} className="fot-link" href="#">services</Link><br/>
                                <Link smooth={true} to="contact" offset={-110} className="fot-link" href="#">contact us</Link><br/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-5 col-sm-6 align-items-center">
                        <div className="d-flex justify-content-center">
                            <a href="https://all-ia-com"><img src="https://img.icons8.com/ios/50/ffffff/monitor--v1.png" alt="Jorge Navarro Portfolio" className="icon"/></a>
                        </div>
                        <div className="col-lg-12 col-md-5 col-sm-6 align-items-center">
                            <p className="pt-3 text-center">
                                    Copyright&copy;
                                    {new Date().getFullYear()}&nbsp;<br />
                                    All Insurance Agency of Florida, LLC. | 
                                    All Rights Reserved
                                </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
