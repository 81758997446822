import React from "react"
import myself from "../images/insurance1.jpeg";

const AboutUs = () => {
    return (
        <div className="about-us">
            <div className="container">
                <div className= "row">
                    <div className= "col-lg-6 col-xm-12">
                        <div className="photo-wrap mb-5">
                            <img className="profile-img" src={myself} alt="myself..."/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xm-12">
                        <h1 className="about-heading">Sobre nosotros</h1>
                        <p>
                        All-Insurance Agency es una empresa familiar de seguros de salud y comerciales, cuyo principal objetivo es proteger y 
                        ayudar a los residentes de Florida a encontrar la cobertura adecuada a sus necesidades al mejor costo.
                        </p>
                        <p>
                        Entendemos el complejo proceso de elegir el seguro adecuado. Nuestra agencia le ofrece una amplia gama de opciones de Seguros de Salud, 
                        Auto, Hogar y Comercial a su disposición, puede confiar en nosotros para ayudarlo a elegir la mejor opción para sus necesidades.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs