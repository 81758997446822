import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
// import Particles from "react-particles-js";
import Navbar from './components/Navbar';
import Header from './components/Header';
import AboutUs from './components/AboutUs';
import ContactUs from './components/Contact';
import Footer from './components/Footer';
import Services from './components/Services';
import Services1 from './components/Services1';
import Routes from './Routes';
import Routes1 from './Routes1';
import Maps from './components/Maps';

function App() {
  return (
    <>
      {/* <Particles
        className="particles-canvas"
        params={{
          particles: {
            number: {
              value: 30,
              density: {
                enable: true,
                value_area: 1000
              }
            },
            shape: {
              type: "circle",
              stroke: {
                width: 6,
                color: "#f9ab00"
              }
            }
          }
        }}
      /> */}
       <Navbar />
       <Header />
       <AboutUs />
       <Services />
       <Routes />
       <Maps />
       <Services1 />
       <Routes1 />
       <ContactUs />
       <Footer />
    </>
  );
}

export default App;
