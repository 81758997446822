import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faCar, faClipboardList, faHouseDamage } from "@fortawesome/free-solid-svg-icons";
import {Button} from 'react-bootstrap';
import history from '../history';
import "../App.css";

const Services = () => {
    return (
        <div className="services">
          <h1 className="py-5">seguros de auto y comerciales</h1>
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-sm-6">
                <div className="box">
                  <div className="circle"><FontAwesomeIcon className="icon" icon={faCar} size="2x" /></div>
                    <h3>Auto Insurance</h3>
                    <p>El seguro de auto es un asunto complicado. Muchas personas tienen una póliza de seguro pero no entienden 
                    exactamente cómo están cubiertos o qué harán si se encuentran en la posición de...</p><br></br><br></br>
                    {/* <Link smooth={true}  onClick={() => ('/Auto')} offset={-110} className="btn" href="#">Leer Más</Link> */}
                    <Button variant="btn btn-success" onClick={() => history.push('/Auto')}>Leer Más</Button>
                </div>
              </div>
              {/* - */}
              <div className="col-md-3 col-sm-6">
                <div className="box">
                  <div className="circle"><FontAwesomeIcon className="icon" icon={faHouseDamage} size="2x" /></div>
                    <h3>Home Insurance</h3>
                    <p>Cuando las personas compran un seguro de hogar, buscan no solo un tipo de cobertura, sino posiblemente varias variedades diferentes. 
                    Homeowners insurance, renters...</p><br></br><br></br>
                    {/* <Link smooth={true} to="footer" offset={-110} className="btn" href="#">Leer Más</Link> */}
                    <Button variant="btn btn-success" onClick={() => history.push('/Home')}>Leer Más</Button>
                </div>
              </div>
              {/* - */}
              <div className="col-md-3 col-sm-6">
                <div className="box">
                  <div className="circle"><FontAwesomeIcon className="icon" icon={faBuilding} size="2x" /></div>
                  <h3>Comerciales</h3>
                  <p>Los dueños de negocios deben considerar cuidadosamente sus necesidades de seguros comerciales, que ofrecen una gama de protección vital relacionada con el negocio...</p><br></br><br></br>
                  {/* <Link smooth={true} to="footer" offset={-110} className="btn" href="#">Leer Más</Link> */}
                  <Button variant="btn btn-success" onClick={() => history.push('/Commercial')}>Leer Más</Button>
                </div>
              </div>
              {/* - */}
              <div className="col-md-3 col-sm-6">
                <div className="box">
                  <div className="circle"><FontAwesomeIcon className="icon" icon={faClipboardList} size="2x" /></div>
                  <h3>Otros Seguros</h3>
                  <p>La mayoría de las personas ya son bastante conscientes de sus opciones en seguros de hogar, seguros de automóviles y seguros de vida. 
                  Sin embargo, no es un hecho muy conocido que puede obtener otro seguro para casi cualquiera de sus posesiones...</p>
                  {/* <Link smooth={true} to="footer" offset={-110} className="btn" href="#">Leer Más</Link> */}
                  <Button variant="btn btn-success" onClick={() => history.push('/Other')}>Leer Más</Button>
                  </div>
              </div>
            </div>
          </div>
        </div>
    )

  }
export default Services;