import React from "react"
import house from "../images/homes.jpeg";

const Home = () => {
    return (
        <div className="pages">
            <div className="container">
                <div className= "row">
                    <div className= "col-lg-6 col-xm-12">
                        <div className="pages-photo-wrap mb-5">
                            <img className="pages-profile-img" src={house} alt="house..."/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xm-12">
                        <h1 className="pages-heading">Home Insurance</h1>
                        <p>
                        Cuando las personas compran un seguro de hogar, buscan no solo un tipo de cobertura, sino posiblemente varias variedades diferentes. El seguro para propietarios de viviendas, el seguro para inquilinos y el seguro para condominios generalmente se incluyen dentro 
                        de la categoría de seguros para el hogar. Ya sea que sea dueño de la propiedad en la que vive o que la esté alquilando a corto plazo, necesita tener una cobertura de seguro para protegerse contra desastres y pérdidas.
                        </p>
                        <h3>Tipos de pólizas para propietarios de viviendas, condominios e inquilinos: Las Diferencias</h3>
                        <p>La póliza de propietario de vivienda es para personas que son dueñas de su propia casa. Esto puede ser una casa o un condominio: Esencialmente, si está haciendo pagos por la casa con la propiedad como objetivo final, necesitará un seguro de propiedad.
                        Este tipo de póliza cubrirá tanto el exterior de la casa como todo el contenido de su casa. Cubrirá la mayoría de los desastres naturales (ciertos desastres como los terremotos pueden quedar excluidos, pero puede agregar cobertura adicional para ese propósito específico)
                        y le permitirá reemplazar o reconstruir la propiedad dañada. Si un ladrón entra en su casa y le roban artículos, su seguro ayudará a reemplazar los artículos robados y pagará cualquier daño en términos de vandalismo que haya hecho el ladrón.</p>
                        <p>Con una póliza de inquilino, la cobertura incluye el contenido interno de la casa, apartamento o habitación que está alquilando. Esta es una cobertura para robos, desastres naturales, incendios y otras circunstancias que están fuera de su control.
                        Aunque su propietario tendrá una póliza de propietario de vivienda en el lugar donde vive, no cubre sus artículos como inquilino, por lo que el seguro de inquilino es esencial.</p>
                        <p>Es posible que se sorprenda mucho al ver la variedad de precios que obtendrá en las cotizaciones de seguros de hogar. A veces puede ahorrar cientos de dólares, o incluso más, simplemente eligiendo una empresa en lugar de otra. Sin embargo, debe obtener cotizaciones solo de los principales transportistas confiables.
                        Su agente independiente local es el mejor recurso para esto, ya que puede acumular múltiples cotizaciones de los principales operadores para usted en línea. ¡Su agencia independiente le dará el poder de comparar todas las mejores opciones de pólizas convenientemente en casa!</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;