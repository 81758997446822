import React from "react"
import car from "../images/car_accident.jpeg";

const Auto = () => {
    return (
        <div className="pages">
            <div className="container">
                <div className= "row">
                    <div className= "col-lg-6 col-xm-12">
                        <div className="pages-photo-wrap mb-5">
                            <img className="pages-profile-img" src={car} alt="car..."/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xm-12">
                        <h1 className="pages-heading">Auto Insurance</h1>
                        <p>
                        El seguro de auto es un asunto complicado. Muchas personas tienen una póliza de seguro pero no entienden exactamente cómo están cubiertos o qué harán si se encuentran en la posición de necesitar utilizar su seguro. 
                        Descubre los tipos básicos de seguros de coche y descubre cómo obtener la cobertura que necesitas para tu situación.
                        </p>
                        <p>&#9642; Cobertura de colisión – Este es el tipo de seguro básico que cubre su vehículo si choca contra algo. Esto cubre las colisiones con otros automóviles, así como si chocara contra una pared, barrera o puente.</p>
                        <p>&#9642; Cobertura amplia – cubre cualquier cosa que le pueda pasar a su automóvil que no esté directamente relacionada con una colisión. Esto incluiría cosas como robo, incendio, golpear a un venado o vandalismo.</p>
                        <p>&#9642; Cobertura de responsabilidad civil – este tipo de cobertura cubre los daños cuando usted tiene la culpa en un accidente y daña el automóvil o la propiedad de otra persona. La cobertura de responsabilidad 
                        a menudo es obligatoria por ley, por lo que usted, como conductor, debe asegurarse de tener una cantidad adecuada.</p>
                        <p>&#9642; Pagos médicos – esto también se denomina PIP en algunos estados. Los pagos médicos cubren cualquier lesión que sufran el conductor o los pasajeros si ocurriera un accidente mientras usted conducía. 
                        El seguro de pago médico varía de un estado a otro, así que asegúrese de tener la cobertura esperada.</p>
                        <p>
                        Ahora que comprende un poco más acerca de los diferentes tipos de seguros de automóviles disponibles, probablemente esté listo para encontrar la cobertura adecuada para usted. 
                        La mejor manera de hacerlo es trabajar con una agencia independiente que le permita comparar cotizaciones de seguros en línea. 
                        Al hacer esto, puede estar seguro de que está obteniendo la mejor oferta y la atención personalizada que necesita.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Auto;