import React from "react"
import rv from "../images/rv.jpeg";

const Other = () => {
    return (
        <div className="pages">
            <div className="container">
                <div className= "row">
                    <div className= "col-lg-6 col-xm-12">
                        <div className="pages-photo-wrap mb-5">
                            <img className="pages-profile-img" src={rv} alt="rv..."/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xm-12">
                        <h1 className="pages-heading">OTROS SEGUROS</h1>
                        <p>
                        La mayoría de las personas ya son bastante conscientes de sus opciones en seguros de hogar, seguros de automóviles y seguros de vida. Sin embargo, no es un hecho muy conocido que puede obtener otro seguro para casi cualquiera de sus posesiones. El hecho de que sus artículos sean un poco fuera de lo común no 
                        significa que no pueda obtener una cobertura de póliza de seguro de alta calidad para su bote, vehículo recreativo (RV) u otros juguetes. Estos son algunos de los conceptos básicos sobre otros tipos de cobertura de seguro.
                        </p>
                        <p>&#9642; Seguro de vehículos recreativos (RV) – este es realmente un tipo de seguro vital porque si posee un vehículo recreativo, es probable que salga a la carretera con él, viajando por diferentes áreas, a menudo diferentes estados. Su vehículo recreativo debe estar asegurado tanto como su conductor diario, 
                        y debido a que su inversión monetaria en el RV es tan sustancial, debe estar seguro de que no perderá esa gran inversión si ocurre un desastre. La cobertura de RV se puede organizar a través de su agencia independiente y, a menudo, se fusiona con su póliza de automóvil actual. Esto a veces también le dará un descuento sustancial en la póliza. 
                        RV coverage can be arranged through your independent agency and is often merged with your current auto policy. This will sometimes yield you a substantial policy discount, as well.</p>
                        <p>&#9642; Seguro de embarcación – ya sea que su embarcación pase la mitad de su vida útil en el garaje o esté en el mar todo el tiempo, el seguro de embarcación es esencial. Su embarcación está expuesta a la intemperie y a los elementos cotidianos sin importar dónde se encuentre, y también puede estar expuesta a vándalos o ladrones. 
                        Su póliza de hogar no cubrirá su embarcación, ya sea que se encuentre en su propiedad en el momento del robo o vandalismo o no. Pregúntele a su agente independiente cómo asegurarse de tener la cobertura complementaria que necesita para garantizar que su embarcación esté protegida.</p>
                        <p>&#9642; Pólizas generales – también puede comparar los precios de las pólizas generales cuando obtiene otros tipos de seguros. El seguro general le brinda una cobertura de responsabilidad civil adicional que excede su póliza de hogar, automóvil o embarcación. Una vez que se alcancen los límites de responsabilidad de esas pólizas, se activará su póliza general. 
                        Estas pólizas cubrirán la cobertura relacionada con la responsabilidad para situaciones como: unidades de alquiler, acusación de difamación o calumnia y arresto falso. La póliza general llenará los vacíos en sus pólizas existentes</p>
                        <p>
                        La mejor manera de obtener una cobertura completa a precios razonables es hablar con su agente independiente para comparar pólizas. ¡Puede obtener fácilmente cotizaciones de múltiples operadores de nivel superior convenientemente a través de Internet de esta manera!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Other;