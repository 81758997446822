import React, { Component } from "react";
import { Router, Switch, Route } from "react-router-dom";

import Auto from "../src/pages/Auto";
import Home from "../src/pages/Home";
import Commercial from "../src/pages/Commercial";
import Other from "./pages/Other";
// import Home from "./Home/Home";
import history from './history';

export default class Routes extends Component {
    render() {
        return (
            <Router history={history}>
                <Switch>
                    {/* <Route path="/" exact component={Auto} /> */}
                    <Route path="/Auto" component={Auto} />
                    <Route path="/Home" component={Home} />
                    <Route path="/Commercial" component={Commercial} />
                    <Route path="/Other" component={Other} />
                </Switch>
            </Router>
        )
    }
}
